<template lang="html">
  <ValidationObserver v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(handleSubmission)" class="Form">
      <div class="Form__wrapper">
        <h1 class="title-3">Login</h1>
        <p class="text-xlg text-semi">Fill in your details to log in</p>
        <formly-form
          :form="form"
          :model="model"
          :fields="fields"
          tag="div"
          class="Form__group"
        ></formly-form>
      </div>
      <button class="btn btn--primary btn--min">Log In</button>
    </form>
    <router-link
      :to="{ name: 'register-player' }"
      class="clickable nav-link block mt-16 text-md"
      >Click here to register</router-link
    >
    <router-link
      :to="{ name: 'password-recovery' }"
      class="clickable nav-link block mt-8 text-md"
      >Forgot your password?</router-link
    >
  </ValidationObserver>
</template>

<script>
  export default {
    name: 'Login',
    data: function() {
      return {
        model: {
          username: null,
          password: null,
        },
        form: {},
        fields: [
          {
            key: 'username',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Username',
              layout: 'full',
            },
          },
          {
            key: 'password',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'password',
              label: 'Password',
              layout: 'full',
            },
          },
        ],
      }
    },
    created() {
      if (this.$store.getters.isAuthenticated) {
        this.$router.push(
          this.$route.query.redirect ? this.$route.query.redirect : 'events',
        )
      }
    },
    methods: {
      handleSubmission() {
        this.$store
          .dispatch('AUTH_REQUEST', { ...this.model, remember: false })
          .then(() => {
            this.$router.push(
              this.$route.query.redirect
                ? this.$route.query.redirect
                : 'events',
            )
          })
      },
    },
  }
</script>
